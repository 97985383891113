<template>
  <div class="new_works">
    <div class="back">
      <i class="iconfont icon-back" @click="$router.go(-1)"></i>
      <span>提现记录</span>
      <div class="money_all">
        <span>累计提现</span>
        <span>{{all_money||0}}</span>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="money"  label="提现金额" align="center"></el-table-column>
      <el-table-column prop="cre_time"  label="申请时间" align="center"></el-table-column>
      <el-table-column prop="status"  label="提现进度" align="center">
        <template slot-scope="scope">
          <div>{{scope.row.ap_status}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status"  label="到账方式" align="center">
        <template slot-scope="scope">
          <div>线下打款</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {withdraw_history } from "@/https/mine_center.js";
export default {
  name:'new_works',
  components:{},
  data(){
    return{
      tableData:[
        {type:'200',works_name:'2020/08/25',chapter:'待审核',all_profit:"线下打款"},
        {type:'200',works_name:'2020/08/25',chapter:'待审核',all_profit:"线下打款"},
        {type:'200',works_name:'2020/08/25',chapter:'拒绝',all_profit:"线下打款"},
        {type:'200',works_name:'2020/08/25',chapter:'通过',all_profit:"线下打款"},
      ],
      all_money:0
    }
  },
  created(){
    this.avoid_info()
  },
  methods:{
    avoid_info(){
      let pramas={}
      withdraw_history(pramas).then((res)=>{
        if(res.code===0){
          this.all_money=res.data.total
          this.tableData=res.data.list
          if(this.tableData&&this.tableData.length){
            this.tableData.forEach((item)=>{
              if(item.status===-1){
                item.ap_status="未通过"
              }else if(item.status===0){
                item.ap_status="待审核"
              }else{
                item.ap_status="已通过"
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../utils/tool.scss";
// @include flex-center();
.new_works{
  @include flex-column();
  .back{
    padding: 15px 0;
    @include flex-row();
    i {
      margin-right:10px ;
      cursor: pointer;
      &:hover{
        color: #409eff
      }
    }
    div{
      margin-left:15px ;
      span:nth-child(2){
        margin-left:10px
      }
    }
  }
}
</style>